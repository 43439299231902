import { EmptyState } from "src/components/EmptyState"
import { useAppData } from "src/context/useAppData"
import { codeVerifierStorage, storedLocation } from "src/data/auth/authStorage"
import { usePostToken } from "src/data/user/queries/userAuthQueries"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useLogout } from "src/hooks/useLogout"
import { useUrlParam } from "src/hooks/useUrlParam"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { ErrorService } from "src/utils/ErrorService"
import { debug, logger } from "src/utils/logger"

export function ExchangeCodeForToken() {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const { value: code, replaceValue: replaceCode } = useUrlParam("code")
  const { logout } = useLogout()
  const postTokenMutation = usePostToken({
    logout: () =>
      logout({
        reason: "POST OAuth token failure autologout",
        userInitiated: false,
      }),
  })
  const { authState } = useAppData()

  useEffectOnce(() => {
    if (!code) {
      throw Error("Code not found in url")
    }
    const codeVerifier = codeVerifierStorage.get()
    if (!codeVerifier) {
      throw Error("Code verifier not found in session storage")
    }

    postTokenMutation.mutate(
      { code, codeVerifier },
      {
        onSuccess: (d) => {
          debug.log("Token successfully exchanged")
          authState.setAuthorization(d)
          const location = storedLocation.pop()
          navigate(location ?? Routes.Dashboard.location(), {
            replace: true,
          })
        },
        onError: (error) => {
          logger.error("Code for token exchange error")

          ErrorService.captureException(error)
          throw error
        },
      }
    )
  })

  function onLogout() {
    replaceCode(undefined)
    logout({
      reason: "User clicked logout after POST OAuth token error",
      userInitiated: true,
    })
  }

  if (postTokenMutation.isError) {
    return (
      <EmptyState
        icon={null}
        title={t(langKeys.failed_something_went_wrong)}
        body={<MButton onClick={onLogout}>{t(langKeys.sign_in)}</MButton>}
      />
    )
  }

  return <LoadingScreen debugInfo={"ExchangeCodeForToken"} />
}
